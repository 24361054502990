<template>
  <div>
    <a-table
      style="overflow: auto"
      size="middle"
      :columns="tableColumns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      :expand-icon="expandIcon"
      row-key="index"
    >
      <div slot-scope="record" slot="expandedRowRender">
        <component
          :is="currentComponent(record.unit_type_slug)"
        />
      </div>
    </a-table>
  </div>
</template>

<script>
import { findAgentConsumptionBills } from '@/api/agent_consumption_bill'
import { formatBigNumber } from '@/utils/filter'

export default {
  name: 'AgentConsumptionBills',
  components: {
    PurchaseBillList: () => import('@/views/agent_consumption_bills/purchase/index'),
    ActiveBillList: () => import('@/views/agent_consumption_bills/active/index'),
    RenewBillList: () => import('@/views/agent_consumption_bills/renew/index'),
    SubscribeBillList: () => import('@/views/agent_consumption_bills/subscribe/index')
  },
  data() {
    return {
      data: [],
      loading: true
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    },

    tableColumns() {
      return [
        {
          title: '账单类型',
          width: 350,
          dataIndex: 'unit_type'
        },
        {
          title: '个数',
          customRender: formatBigNumber,
          dataIndex: 'bill_count'
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    expandIcon({ expanded, expandable, record, onExpand }) {
      if (!expandable || record.bill_count === 0) return null

      return (
        <a onClick={e => onExpand(record, e)}>
          {expanded ? <a-icon type='minus-square' /> : <a-icon type='plus-square' />}
        </a>
      )
    },

    currentComponent(unit_type_slug) {
      // todo 续期账单和订购账单未完成
      switch (unit_type_slug) {
        case 'purchase':
          return 'PurchaseBillList'
        case 'active':
          return 'ActiveBillList'
        case 'renew':
          return 'RenewBillList'
        case 'subscribe':
          return 'SubscribeBillList'
        default:
          if (unit_type_slug !== '') {
            console.log(`请配置 ${unit_type_slug}`)
          }
          return ''
      }
    },

    fetchData() {
      this.loading = true
      findAgentConsumptionBills({ agent_id: this.agentId }).then((res) => {
        if (res.code === 0) {
          this.data = [
            { index: 0, unit_type: '采购账单', unit_type_slug: 'purchase', bill_count: res.data.purchased_count },
            { index: 1, unit_type: '激活账单', unit_type_slug: 'active', bill_count: res.data.actived_count },
            { index: 2, unit_type: '续期账单', unit_type_slug: 'renew', bill_count: res.data.renewed_count },
            { index: 3, unit_type: '订购账单', unit_type_slug: 'subscribe', bill_count: res.data.subscribed_count }
          ]
        }
        this.loading = false
      })
    }
  }
}
</script>
