import request from '@/utils/request'

// 查询客户消费账单数据
export function findAgentConsumptionBills(params) {
  return request({
    url: `/agent_consumption_bills`,
    method: 'get',
    params: params
  })
}

// 查询消费账单采购账单列表
export function findAgentConsumptionPurchaseBills(params) {
  return request({
    url: `/agent_consumption_bills/purchase`,
    method: 'get',
    params: params
  })
}

// 导出消费账单采购账单列表
export function exportAgentConsumptionPurchaseBills(data) {
  return request({
    url: `/agent_consumption_bills/purchase/export`,
    method: 'post',
    data
  })
}

// 查询消费账单续期账单列表
export function findAgentConsumptionRenewBills(params) {
  return request({
    url: `/agent_consumption_bills/renew`,
    method: 'get',
    params: params
  })
}

// 导出消费账单续期账单列表
export function exportAgentConsumptionRenewBills(data) {
  return request({
    url: `/agent_consumption_bills/renew/export`,
    method: 'post',
    data
  })
}

// 查询消费账单订购账单列表
export function findAgentConsumptionSubscribeBills(params) {
  return request({
    url: `/agent_consumption_bills/subscribe`,
    method: 'get',
    params: params
  })
}

// 导出消费账单订购账单列表
export function exportAgentConsumptionSubscribeBills(data) {
  return request({
    url: `/agent_consumption_bills/subscribe/export`,
    method: 'post',
    data
  })
}

// 查询消费账单激活账单列表
export function findAgentConsumptionActiveBills(params) {
  return request({
    url: `/agent_consumption_bills/active`,
    method: 'get',
    params: params
  })
}

// 导出消费账单激活账单列表
export function exportAgentConsumptionActiveBills(data) {
  return request({
    url: `/agent_consumption_bills/active/export`,
    method: 'post',
    data
  })
}
